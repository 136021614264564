import React from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import CtaFooter from "../components/sections/cta-footer";
import Features from "../components/sections/features";
import Footer from "../components/sections/footer";
import PricingHeader from "../components/sections/pricing-header";



const PricingPage = () => (
  <Layout>
    <SEO
      title="Ranger Pricing"
      description="Pricing for Ranger, the no-code SaaS solution for centralizing and protecting your Excel data by connecting it to the cloud."
    />
    <Navigation />
    <PricingHeader />
    <Features />
    <CtaFooter />
    <Footer />
  </Layout>
);

export default PricingPage;
