import React from "react";
import styled from "styled-components";

import { Section, Container } from "../global";

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>Features</Subtitle>
      <SectionTitle>Free your Excel data</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Never Leave Excel</FeatureTitle>
          <FeatureText>
            Cut down on context switching and costly errors and have all your
            data at your fingertips in the Excel sidebar.
          </FeatureText>
        </FeatureItem>

        <FeatureItem>
          <FeatureTitle>No-code Solution</FeatureTitle>
          <FeatureText>
            Create your own database, on-demand, without writing a single line
            of code.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Easy Setup</FeatureTitle>
          <FeatureText>
            Get started in minutes, using software you already know.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Cloud Connected</FeatureTitle>
          <FeatureText>
            Get your data off the shared drive and onto the cloud, where you can
            actually use it.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Collaborative Data</FeatureTitle>
          <FeatureText>
            Your teams are more efficient when it's easier to work together.
          </FeatureText>
        </FeatureItem>

        <FeatureItem>
          <FeatureTitle>Version Control</FeatureTitle>
          <FeatureText>
            Track version history and eliminate costly data errors.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
);

export default Features;

const StyledContainer = styled(Container)``;

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`;

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`;

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`;

const FeatureText = styled.p`
  text-align: center;
`;
